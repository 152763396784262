html { font-size: initial;}
body { font-size: $font-size-base;}

#TommyBookingSupport {
  .tbs-container-fluid {
    @extend .px-0;
  }

  .tbs-navbar-collapse {
    @extend .px-0;
  }

  .tbs-nav {
    li {
      font-size: 14px;
    }
  }

  .tbs-page-overzicht {
    #coupon_code {
      h2 {
        margin-top: 20px;
      }
    }
  }
}

/* button colors */
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav > .tbs-active > a,
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav > .tbs-active > a:focus,
#TommyBookingSupport .tbs-navbar-default .tbs-navbar-nav > .tbs-active > a:hover {
  background-color: $primary;
  color: $white;
}
#TommyBookingSupport .tbs-btn-info {
  background-color: $primary;
  border-color: $primary;
  color: $white;
}
#TommyBookingSupport .tbs-btn-info:hover {
  background-color: $secondary;
  border-color: $secondary;
  color: $white;
}
#TommyBookingSupport .tbs-btn-success {
  background-color: $primary;
  border-color: $primary;
  color: $white;
}
#TommyBookingSupport .tbs-btn-success:hover {
  background-color: $secondary;
  border-color: $secondary;
  color: $white;
}
#TommyBookingSupport .tbs-btn-info,
#TommyBookingSupport .tbs-btn-success {
  transition: 0.5s;
}
#TommyBookingSupport .tbs-calendar .tbs-day.tbs-selected {
  background-color: $primary !important;
}



/* custom - op basis van TommyBookingSupport MINI Widget */
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types { order: 0;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories { order: 1;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date { order: 2;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini tommy-confirm-update { order: 3;}

.mini-sab .tommy-zeb-wrapper {
    float: none;
    width: auto;
}
.mini-sab .tommy-zeb-wrapper * {
    font-family: $font-poppins !important;
    font-size: 14px;
}
.mini-sab .tommy-zeb-wrapper .tommy-zeb-left {
    margin-top: -10px;
    float: none;
    background: none;
    color: $white;
    padding: 0 !important;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini h2.ng-binding { display: none; }
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-left { flex-wrap: wrap; justify-content: center;}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories,
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date,
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types {
    margin-right: 10px;
    padding-right: 0;
    border-right: none;
}
tommy-widget .tommy-zeb-wrapper.tommy-zeb-wrapper-mini tommy-person-category-mini .tommy-zeb-person-category-list { background-color: #059de4; }


/* Accommodatietype */
/*.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types { display: block !important;}*/
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types .ng-isolate-scope {
    padding: 5px 0px;
}

.mini-sab .tommy-zeb-wrapper .tommy-zeb-left input { line-height: 20px;}
.mini-sab .tommy-zeb-wrapper .tommy-zeb-left input,
.mini-sab .tommy-zeb-wrapper .tommy-zeb-left select { padding: 5px; margin: 0; width: 160px;}
.mini-sab .tommy-zeb-period { display: flex;}
.mini-sab .tommy-zeb-period .tommy-zeb-date-begin { margin-right: 10px;}
.mini-sab .tommy-zeb-period .tommy-zeb-date-begin-label,
.mini-sab .tommy-zeb-period .tommy-zeb-date-end-label { float: none !important; display: block;}
.mini-sab .tommy-zeb-period .tommy-zeb-date-begin-label label, .mini-sab .tommy-zeb-period .tommy-zeb-date-end-label label { margin-bottom: 0 !important; line-height: 30px; display: block;}
.mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-date-begin,
.mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-date-end {
    padding: 5px 0px;
    float: left;
    margin-bottom: 0;
}

.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories, .mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-date {
    border-right: none;
    padding-right: 0;
}

.mini-sab .tommy-zeb-wrapper .tommy-zeb-left select,
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-period input {
    background-color: $white;
    border:none;
    padding: 8px;
    border-radius: 2px;
    height: 36px;
}


.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn {
    float: none;
    min-width: 140px;
    margin: 35px 0 0 0 !important;
    border: none;
    border-radius: 2px;
    background-color: $secondary;
    font-family: $font-poppins !important;
    color: $white;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    cursor: pointer;
}
.mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn:hover {
    background-color: $tertiary;
    color: $white;
}


.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories {
	display: flex;
	align-items: flex-end;
	margin-top: -4px;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories tommy-person-category-mini .tommy-zeb-person-categories-collapse {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	width: 100%;
}
.mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-date, .mini-sab .tommy-zeb-wrapper-mini .ng-isolate-scope {
	width: 100%;
}
.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories-collapse .tommy-zeb-travelcompany-total {
	height: 37px;
	width: 50px !important;
	margin-left: 0;
	margin-top: 0 !important;
}
body .tommy-zeb-wrapper.tommy-zeb-wrapper-mini tommy-person-category-mini .tommy-zeb-person-categories-collapse button.tommy-zeb-btn {
	margin-top: 0 !important;
}
body.default .mini-sab .container-holder h5 {
	line-height: 34px;
}

@media (min-width: 768px) and (max-width: 1199px) {

    .mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-person-category-label,
    .mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-period .tommy-zeb-date-begin-label,
    .mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-period .tommy-zeb-date-end-label {
      width: auto;
    }

}
@media (min-width: 768px) and (max-width: 991px) {

    .mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn.tommy-zeb-update { margin-top: 10px !important;}

}
@media (max-width: 767px) {

	.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories {
		height: auto;
	}

    .mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-left { display: block;}
    .mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories,
    .mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types {
        border-bottom: none;
        padding: 0;
        margin: 0;
    }
    .mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-date,
    .mini-sab .tommy-zeb-wrapper-mini .ng-isolate-scope { float: none; clear: both;}
    .mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn { margin: 10px 0 0 !important; }
    .mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-period .tommy-zeb-date-begin-label,
    .mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-period .tommy-zeb-date-end-label { width: auto;}
    .mini-sab .tommy-zeb-wrapper-mini tommy-confirm-update { display: block;}

    .mini-sab .tommy-zeb-wrapper .tommy-zeb-left select,
    .mini-sab .tommy-zeb-wrapper .tommy-zeb-left input,
     .mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn {
        display: block !important;
        width: 100% !important;
    }
    .mini-sab .tommy-zeb-wrapper-mini button.tommy-zeb-btn {
        display: inline-block!important;
    }

}



/* custom - op basis van TommyBookingSupport */
.search_book { @extend .clearfix;}
.search_book .tommy-zeb-wrapper * { font-family:  $font-poppins !important;}
.search_book .tommy-zeb-wrapper .tommy-zeb-left { background: $primary;}
.search_book .tommy-zeb-wrapper .tommy-zeb-result.ng-scope { background: rgba($primary, 0.1);}
.search_book .tommy-zeb-wrapper .tommy-zeb-left h1 { display: none;}
.search_book .tommy-zeb-wrapper * h2 {
    margin: 15px 0 10px 0 !important;
    color: #fff !important;
    font-size: 20px !important;
    font-weight: 700;
    font-family: $font-poppins !important;
}
.search_book .tommy-zeb-wrapper .tommy-zeb-person-categories h2 { margin-top: 0 !important;}
.search_book .tommy-zeb-wrapper .tommy-zeb-btn {
    padding: 7px 30px;
    border-radius: 18px;
    border: 1px solid $primary;
    background-color: $primary;
    color: $white;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    font-family: $font-poppins !important;
}
.search_book .tommy-zeb-wrapper .tommy-zeb-btn:hover {
    border-color: $secondary;
    background-color: $secondary;
    color: $white;
}
.search_book .tommy-zeb-wrapper .tommy-zeb-result-label.ng-binding {
    margin-bottom: 10px;
    color: $primary;
    font-size: 24px;
    font-weight: 700;
    font-family:  $font-poppins !important;
}
.search_book .tommy-zeb-wrapper .tommy-zeb-result-price.ng-binding {
    color: $black;
    font-weight: 700;
}

@media (min-width: 1200px) {
    /* custom - op basis van TommyBookingSupport - Zoek en Boek */
    .search_book .tommy-zeb-wrapper .tommy-zeb-result-image img { max-width: 250px;}
    .search_book .tommy-zeb-wrapper .tommy-zeb-result-details { width: 520px;}
}
@media (min-width: 980px) and (max-width: 1199px) {
    /* custom - op basis van TommyBookingSupport - Zoek en Boek */
    .search_book .tommy-zeb-wrapper .tommy-zeb-result-image img { max-width: 180px;}
}


@media (min-width: 768px) {
	.tommy-zeb-wrapper.tommy-zeb-wrapper-mini tommy-person-category-mini .tommy-zeb-person-categories-collapse,
	.mini-sab .tommy-zeb-wrapper-mini .tommy-zeb-date, .mini-sab .tommy-zeb-wrapper-mini .ng-isolate-scope {
		width: auto;
	}

	.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories .tommy-zeb-person-categories-collapse {
		width: 100%;
	}
}

@media (min-width: 768px) and (max-width: 980px) {
	.tommy-zeb-wrapper.tommy-zeb-wrapper-mini tommy-person-category-mini .tommy-zeb-person-categories-collapse {
		margin-bottom: 4px;
	}
}

@media (min-width: 980px) {
	.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories tommy-person-category-mini,
	.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories .tommy-zeb-person-categories-collapse {
		width: 100%;
	}

	.mini-sab .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories {
		height: 76px;
	}
}
