/* fix (https://tommybookingsupport.com/widget/css/tommy.style.css) */
.accommodation_detail .logo { width: auto;}
.accommodation_detail .main { padding: revert;}

/* new color file - october 2021 */
.tommy-matrix .the-matrix {
    font-family: 'Barlow', sans-serif;
}
.tommy-matrix .matrix-bar { 
    margin: 10px auto;
}
.tommy-matrix .matrix-bar legend {
    color: $matrix-color-primary;
}
.tommy-matrix .matrix-bar label.active textarea,
.tommy-matrix .matrix-bar label.active input,
.tommy-matrix .matrix-bar label.active select {
    border-color: $black;
}
.tommy-matrix .matrix-bar .input-ok input,
.tommy-matrix .matrix-bar .input-ok select,
.tommy-matrix .matrix-bar label.input-ok::after {
    color: $black;
}
.tommy-matrix .the-matrix button,
.tommy-matrix .the-matrix .btn {
    background: $matrix-color-primary;
    border-color: $matrix-color-primary;
    box-shadow: none;
    color: $white;
}
.tommy-matrix .the-matrix button:hover,
.tommy-matrix .the-matrix .btn:hover {
    background: $matrix-color-secondary;
    border-color: $matrix-color-secondary;
    color: $white;
}
.tommy-matrix .the-matrix .popup .input-container_header {
    background: $matrix-color-primary;
    color: $white;
}
.tommy-matrix .the-matrix .popup:after {
    border-bottom-color: $matrix-color-primary;
}
.tommy-matrix .matrix-bar .ul-nav li a:hover {
    color: $matrix-color-primary;
}
.tommy-matrix .matrix-bar .ul-nav li a i {
    color: $matrix-color-primary;
}
.tommy-matrix .the-matrix .popup .show-col-table tr td {
    color: $matrix-color-primary;
}
.tommy-matrix .the-matrix .popup .show-col-table tr.td-total td {
    color: $matrix-color-primary;
}
.tommy-matrix .matrix-bar .persons-table td a {
    color: $matrix-color-primary;
}
.tommy-matrix .matrix-bar .persons-table td a:hover {
    color: $matrix-color-primary;
}
.tommy-matrix .matrix-bar .persons-table td input:hover,
.tommy-matrix .matrix-bar .persons-table td input:focus {
    color: $matrix-color-primary;
}
.tommy-matrix .time-bar ul li a {
    color: $matrix-color-primary;
}
.tommy-matrix .time-bar .time-back {
    color: $matrix-color-primary;
}
.tommy-matrix .time-bar .time-ff {
    color: $matrix-color-primary;
}
.tommy-matrix .matrix-date-row .matrix-time i {
    color: $matrix-color-primary;
}
.tommy-matrix .matrix-row.arrangement .row-arrang {
	background: rgba($matrix-color-secondary, 0.1);
}
.tommy-matrix .matrix-row.arrangement .fixed-one {
    border-color: $black;
}
.tommy-matrix .row-arrang .title {
    color: $black;
}
.tommy-matrix .row-arrang .price {
    color: $black;
}
.tommy-matrix .matrix-row .fixed-one {
    border-left: 5px solid $matrix-color-primary;
}
.tommy-matrix .matrix-row .fixed-one .fixed-one_text {
    background: $matrix-color-primary;
}
.tommy-matrix .matrix-row .fixed-one .fixed-one_text .more-info:hover {
    color: $matrix-color-secondary;
}
.tommy-matrix .matrix-beschikbaarheid {
    background: rgba($matrix-color-primary, 0.2);
}
.tommy-matrix .matrix-beschikbaarheid .matrix-prijs {
    color: $matrix-color-primary;
}
.tommy-matrix .matrix-beschikbaarheid .matrix-trigger {
    color: $matrix-color-primary;
}
.tommy-matrix .matrix-beschikbaarheid:hover {
    background: $matrix-color-secondary;
}
.tommy-matrix .matrix-beschikbaarheid:hover .matrix-spec {
    color: $white;
}
.tommy-matrix .matrix-beschikbaarheid:hover .matrix-prijs {
    color: $white;
}
.tommy-matrix .matrix-beschikbaarheid:hover .matrix-trigger {
    color: $white;
}
.tommy-matrix .matrix-beschikbaarheid .matrix-spec .vanvoor::after {
    border-color: $black;
}
.tommy-matrix .matrix-arrangement {
    background: $black;
}
.tommy-matrix .matrix-arrangement:hover .matrix-spec {
    color: $matrix-color-primary;
}
.tommy-matrix .matrix-arrangement:hover .matrix-prijs {
    color: $matrix-color-primary;
}
.tommy-matrix .matrix-arrangement:hover .matrix-trigger {
    color: $matrix-color-primary;
}
.tommy-matrix .matrix-lastminute {
    background: $matrix-color-secondary;
    color: $white;
}
.tommy-matrix .matrix-lastminute:hover .matrix-spec {
    color: $matrix-color-primary;
}
.tommy-matrix .matrix-lastminute:hover .matrix-prijs {
    color: $matrix-color-primary;
}
.tommy-matrix .matrix-lastminute:hover .matrix-trigger {
    color: $matrix-color-primary;
}
.tommy-matrix .matrix-beschikbaarheid.no-data {
    background: rgba($matrix-color-primary, 0.1);
}

@media (max-width: 768px) {
    /* pop-up fix */
    .tommy-matrix .the-matrix.periods .popup {
        width: auto;
        height: auto;
        max-width: 100% !important;
        top: 15px !important;
        left: 15px !important;
        right: 15px !important;
        bottom: inherit;
    }
}