// common reusable placeholders classes
.flex-center {
	display: flex;
	align-items: center;
}
.list-default {
	margin: 0;
	padding: 0;
	list-style: none;
}
.text-center {
	text-align: center;
}
// common element styles
p {
	font-weight: $font-weight-regular !important;
	font-size: $font-size-base / 1.0667;
	min-height: 1rem;
}
img {
	max-width: 100%;
}

h1 {
	@include media-breakpoint-up(down) {
		font-size: $font-size-base * 2.25 !important;
	}
}
