&.blog_post_overview,
&.accommodation_overview,
&.service_overview {
	.content.overview {
		padding: 50px 0 20px;
		position: relative;

		.container-holder {
			max-width: 730px;
			margin: auto;
			text-align: center;

			p {
				font-weight: $font-weight-regular;
				font-size: $font-size-base * 0.938;
			}
		}
	}
	.bg-slant-top {
		position: relative;

		.container-default {
			position: relative;
			padding: 0 !important;

			&::before {
				background: url("/images/green-top-path.svg") no-repeat bottom/100%;
				content: "";
				position: absolute;
				width: 100%;
				height: 170px;
				top: 0;
				z-index: 12;
				right: 0;
				background-color: $white;

				@include media-breakpoint-up(sm) {
					height: 260px;
				}
			}

			&::after {
				background: url("/images/green-bottom-path.svg") no-repeat top/100%;
				content: "";
				position: absolute;
				width: 100%;
				height: 150px;
				bottom: 0;
				right: 0;
				z-index: 11;
				background-color: $white;

				@include media-breakpoint-up(sm) {
					height: 230px;
				}
			}

			.container-holder {
				position: relative;

				.grid {
					@extend .container;
					padding: 0 !important;
					margin: 0 auto;
					position: relative;

					&::before {
						position: absolute;
						content: "";
						width: 100vw;
						height: 100%;
						background: $primary;
					}

					.item {
						z-index: 12;

						@include media-breakpoint-up(lg) {
							@include make-col(4);
						}
					}
				}
			}
		}
	}
}
.grid.accommodation_overview {
	.item {
		@include media-breakpoint-up(xl) {
			@include make-col(4);
		}
	}
}
.content.detail {
	padding: 50px 0 0;

	@include media-breakpoint-up(sm) {
		padding: 50px 0;
	}
	.container-holder {
		.intro {
			width: 100%;
			margin: 0 auto 2.5rem;
			margin-bottom: 70px;
			text-align: center;

			@include media-breakpoint-up(lg) {
				width: 70%;
			}
			h1 {
				margin-bottom: 10px;
			}
			p {
				margin-bottom: 0;
				font-size: $font-size-base * 0.938;

				@include media-breakpoint-up(sm) {
					font-size: $font-size-base * 1.0625;
				}
			}
			.btn-back {
				color: $secondary;
				font-family: $font-poppins;

				&:hover {
					text-decoration: none;
					color: lighten($secondary, 10%);
				}
			}
		}
		.info {
			font-weight: $font-weight-regular;
			font-size: $font-size-base * 0.938;
			position: relative;

			@include media-breakpoint-down(sm) {
				order: 2;
				margin-top: 30px;
			}

			p {
				font-size: $font-size-base / 1.0667;
				font-weight: $font-weight-regular;
			}

			.accommodation-properties {
				position: absolute;
				top: -90px;
				width: 100%;
				text-align: center;
				margin: auto;
				display: flex;
				align-items: center;
				justify-content: center;
				left: 0;
				padding-top: 20px;

				@include media-breakpoint-up(lg) {
					left: 50%;
				}

				&__icon {
					color: $secondary !important;
					i {
						font-weight: 600;
					}
				}
			}
		}
		.info-extra {
			margin-bottom: 0 !important;
			img {
				border-radius: 5px;
			}
		}
		.tommy {
			margin-top: 6vh;
		}
	}
}
&.service_category {
	.bg-slant-top {
		.container-default {
			@extend .container;

			.container-holder {
				.service_category_detail {
					.intro {
						width: 100%;
						margin: 0 auto 2.5rem;
						margin-bottom: 70px;
						text-align: center;

						@include media-breakpoint-up(lg) {
							width: 70%;
						}
						h1 {
							margin-bottom: 10px;
						}
						p {
							margin-bottom: 0;
							font-size: $font-size-base * 0.938;
							@include media-breakpoint-up(sm) {
								font-size: $font-size-base * 1.0625;
							}
						}
						.btn-back {
							color: $secondary;
							font-family: $font-poppins;

							&:hover {
								text-decoration: none;
								color: lighten($secondary, 10%);
							}
						}
					}
					.info {
						font-weight: $font-weight-regular;
						font-size: $font-size-base * 0.938;
						position: relative;

						@include media-breakpoint-down(sm) {
							order: 2;
							margin-top: 30px;
						}

						p {
							font-size: $font-size-base / 1.0667;
							font-weight: $font-weight-regular;
						}
						.btn-back {
							color: $secondary;
							font-size: $font-size-base;
							font-family: $font-poppins;

							&:hover {
								text-decoration: none;
								color: lighten($secondary, 10%);
							}
						}
					}
					.info-extra {
						margin-bottom: 0 !important;
						img {
							border-radius: 5px;
						}
					}
				}
				.grid {
					.item {
						@include media-breakpoint-up(lg) {
							@include make-col(4);
						}
						.card {
							box-shadow: 3px 3px 6px rgba($black, 0.16);
							border-radius: 5px;
							border: none;

							&-body {
								padding: 25px 15px 50px !important;

								.card-caption {
									.card-title-link {
										.card-title {
											color: $primary;
										}
									}
									.card-title {
										color: $primary;

										&:hover {
											text-decoration: none;
											color: $black !important;
										}
									}
									.card-description {
										min-height: 100%;
										max-height: 100%;
										pointer-events: none;
										margin-top: 0;

										&-content {
											font-weight: $font-weight-regular;
											font-size: $font-size-base / 1.0667;
										}
										&::after {
											content: none;
										}
									}
								}
								.card-buttons {
									.card-btn {
										@extend .btn-secondary;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
