$body-padding: 0;
$body-sticky-padding: 0;

$base-1: #729B60;
$base-2: #6eab53;
/* ==========================================================================
   owl-carousel
   ========================================================================== */
   $carousel-min_height: 50vh !default;
   $carousel-min_height_lg: 95vh !default;

