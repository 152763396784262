.eyecatcher {
    &.large {
        @include media-breakpoint-up(lg) {
            min-height: $carousel-min_height_lg !important;
        }

        // logo
        .logo {
            z-index: 100;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 15px;
            right: 15px;
            max-width: 300px;
            margin: 0 auto;
            @include media-breakpoint-up(xl) {
                max-width: 350px;
            }
        }

        .eyecatcher-container {
            .container-holder {
                .owl-carousel {
                    min-height: $carousel-min_height_lg !important; // !important needed to override base css

                    @include media-breakpoint-up(lg) {
                        min-height: $carousel-min_height-lg !important; // !important needed to override base css
                    }
                    .owl-stage-outer {
                        .owl-stage {
                            .owl-item {
                                .item {
                                    position: relative;
                                    min-height: $carousel-min_height_lg !important; // !important needed to override base css

                       
                                    @include media-breakpoint-up(lg) {
                                        min-height: $carousel-min_height_lg !important; // !important needed to override base css
                                    }
                                    &::after {
                                        content: "";
                                        position: absolute;
                                        background: linear-gradient(180deg, transparent, #161405);
                                        bottom: 0;
                                        left: 0;
                                        height: 60%;
                                        width: 100%;
                                        opacity: 0.6;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &-container {
        position: relative;

        &::after {
            background: url("/images/banner-bottom-curve.svg") no-repeat top/100%;
            content: "";
            position: absolute;
            width: 100%;
            height: 70px;
            bottom: -35px;
            z-index: 1;
            right: 0;

            @include media-breakpoint-up(lg) {
                height: 148px;
            }
        }
    }

}
