.mini-sab {
    padding: 0;
    background: none;
    .container {
        position: relative;
        padding: 0 15px;
    }
    .container-holder {
        z-index: 100;
        position: relative;
        margin: -40% 15px 0 15px;
        padding: 20px 25px;
        border-radius: 4px;
        background: $primary;
        box-shadow: 3px 3px 6px rgba($black, 0.15);       
        color: #fff;
        @include media-breakpoint-up(md) {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            max-width: 860px;
            margin: 0 auto;
            padding: 40px 55px 25px 55px;
        }
        h2 {
            margin-bottom: 5px;
            color: $white;
        }
        h5 {
            margin-top: 25px;
            color: $white;
            font-size: 17px;
            font-weight: 500 !important;
        }
        .logo {
            position: absolute;
            left: 0;
            bottom: 100%;
            margin-bottom: 15px;
            max-width: 288px;
            @include media-breakpoint-up(xl) {
                max-width: 414px;
            }
        }
    }
    &.mini-sab-small {
        @include media-breakpoint-down(md) {
            display: none;
        }
        .container-holder {
            padding: 10px 25px;
            @include media-breakpoint-up(lg) {
                margin-bottom: 40px;
            }
        }
    }
}