.header {
	background-color: $white;
	box-shadow: 0 3px 6px rgba($black, 0.15);

	// header-main
	.header-main {

		// navbar
		.navbar {
			min-height: 115px;
			padding: 5px 15px;

			@include media-breakpoint-up(xl) {
				min-height: 125px;
				padding: 10px 20px 10px 30px;
			}
		}

		// logo
		.logo {
			position: absolute;
			top: 15px;
			left: 15px;

			@include media-breakpoint-up(xl) {
				top: 20px;
				left: 30px;
			}

			max-width: 125px;
		}

		// menu
		.menu {
			@include media-breakpoint-up(xl) {
				margin-right: 30px;
			}

			@include media-breakpoint-down(lg) {
				order: 4;
				margin-top: 30px;

				.dropdown-menu {
					padding: 0 0.5rem;
				}
			}
		}

		// navbar-toggler
		.navbar-toggler {
			@include media-breakpoint-down(xs) {
				order: 1;
				margin: 0 0 0 auto;
			}

			display: flex;
			margin: 0 20px 0 auto;
			padding: 0;
			color: $primary;
			font-size: 18px;
			font-weight: 700;
			line-height: 40px;
			text-transform: uppercase;

			.navbar-toggler-icon {
				margin-right: 5px;
			}
		}

		// social-icons
		.social-icons {
			@include media-breakpoint-down(xs) {
				margin-left: auto;
			}

			margin-bottom: 0;

			a {
				display: block;
				width: 24px;
				height: 24px;
				font-size: 24px;
				line-height: 24px;
				overflow: hidden;
			}
		}

		// book-button
		.book-button {
			@include media-breakpoint-down(xs) {
				margin-top: 10px;
			}

			width: 100%;
			justify-content: flex-end;
			margin-bottom: 0;

			li {
				margin-right: 0;

				a {
					@extend .btn;
					@extend .btn-primary;
				}
			}
		}
	}

	// sticky
	&.sticky {
		box-shadow: 0 3px 6px rgba($black, 0.15);

		.header-main {

			// logo
			.logo {
				@include media-breakpoint-up(xl) {
					max-width: 125px;
				}
			}
		}
	}

	// site_switcher
	.site_switcher {
		ul {
			flex-direction: row;

			li {
				+li {
					margin-left: 5px;
				}

				a {
					padding: 0 !important;
					border-radius: 100%;
					border: 1px solid transparent;
					transition: 0.5s;
					overflow: hidden;

					img {
						display: block;
						width: 22px;
						height: 22px;
					}

					&:hover:not(.active) {
						border-color: #fff;
						box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
					}
				}
			}
		}
	}

}
