&.blog_post_overview,
&.accommodation_overview,
&.service_overview {
    .card {
        box-shadow: 3px 3px 6px rgba($black, 0.16);
        border-radius: 5px;
        border: none;

        &-body {
            padding: 25px 15px !important;

            .card-caption {
                display: flex;
                flex-direction: column;

                .card-title-link {
                    &:hover {
                        text-decoration: none;

                        .card-title {
                            color: $black !important;
                        }
                    }
                    .card-title {
                        font-weight: $font-weight-regular;
                        color: $primary;
                    }
                }
                .card-subtitle {
                    font-weight: $font-weight-regular !important;
                    font-size: $font-size-base * 0.938;
                }
                .card-description {
                    min-height: 140px;
                    font-weight: $font-weight-regular;

                    &-content {
                        font-weight: $font-weight-regular;
                        font-size: $font-size-base / 1.0667;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        min-height: 70px;
                        margin-bottom: 20px;
                    }
                    &::after {
                        content: none;
                    }
                    .list-icons {
                        .list-item {
                            .icon {
                                color: $highlight;
                                i {
                                    font-weight: 600;
                                }
                            }
                            .label {
                                font-weight: 500;
                                font-size: $font-size-base * 0.938;
                            }
                        }
                    }
                }
            }
            .card-buttons {
                .card-btn {
                    @extend .btn-secondary;
                }
            }
        }
    }
}
.card {
    box-shadow: 3px 3px 6px rgba($black, 0.16);
    border-radius: 5px;
    border: none;

    &-body {
        .card-caption {
            .card-description {
                min-height: 100%;
                max-height: 100%;
				pointer-events: none;
				
                &::after {
                    content: none;
                }
                &-content {
                    font-size: $font-size-base / 1.0667;
                    font-weight: $font-weight-regular;
                }
            }
        }
        .card-buttons {
            .card-btn {
                @extend .btn-secondary;
            }
        }
    }
}
