.btn {
    display: inline-flex;
    align-items: center;
    padding: 5px 20px;
    font-size: $font-size-base !important;
    font-family: $font-poppins;
    border-radius: 2px;
    color: $white;
    text-decoration: none;
    transition: 0.3s;

    &-primary {
        color: $white;
        background: $primary;

        &:hover {
            color: $primary;
            background: $white;
            border: 1px solid $primary;
        }
    }
    &-secondary {
        @extend .btn;
        color: $white;
        background: $secondary;

        &:hover {
            color: $secondary;
            background: $white;
            border: 1px solid $secondary;
        }
    }
    &-outline-primary {
        color: $primary;

        &:hover {
            background: $primary;
            color: $white;
        }
    }
    &-outline-secondary {
        color: $secondary;

        &:hover {
            background: $secondary;
            color: $white;
        }
    }
}
