.footer {
    &-top {
        background: $white !important;

        .container-default {
            .container-holder {
                .footer_logolink {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    margin: 0 -15px 0;

                    .list {
                        &-item {
                            &:not(:last-of-type) {
                                margin-right: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
    &-middle {
        background: $base !important;
        padding: 50px 0;

        .container-two-columns {
            .container-holder {
                .column.one {
                    .footer_text {
                        color: $white;

                        h1 {
                            color: $white;
                            font-size: $h3-font-size !important;
                            font-weight: $font-weight-regular;
                        }
                        p {
                            margin-bottom: 0;
                        }
                    }
                }
                .column.two {
                    display: flex;
                    align-items: center;

                    .vocabulary_term_list {
                        &:first-of-type {
                            li {
                                margin-right: 10px;

                                @include media-breakpoint-up(sm) {
                                    margin-right: 20px;
                                }
                                a {
                                    background: $white;
                                    color: $secondary;
                                    padding: 10px 20px;
                                    border-radius: 20px;
                                    text-decoration: none;
                                    font-weight: 700;

                                    &:hover {
                                        background: $secondary;
                                        color: $white;
                                    }
                                }
                            }
                        }
                        &:last-of-type {
                            flex-wrap: nowrap !important;
                            li {
                                margin: 0px 12px 0 0;

                                @include media-breakpoint-up(sm) {
                                    margin: 0px 22px 0 0;
                                }

                                a {
                                    font-size: 0;
                                    background: transparent !important;

                                    &:hover {
                                        i {
                                            opacity: 0.7;
                                        }
                                    }

                                    i {
                                        font-size: $font-size-base * 2.25;
                                        color: $white !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &-socket {
        background: $primary !important;
        text-align: center;
        padding: 100px 0 !important;

        .container-holder {
            display: flex;
            flex-direction: column;
            margin: auto;
            align-items: center;

            .page_block.logo {
                max-width: 253px;
            }
            .footer_text {
                margin-top: 80px;

                p {
                    color: $white;
                    font-size: $font-size-base / 1.0667;
                   font-weight: $font-weight-regular;

                    a {
                        color: $white;
                         font-weight: $font-weight-regular;

                        &:first-of-type {
                            @include media-breakpoint-down(sm) {
                                display: flex;
                                justify-content: center;    
                            }
                       }
                    }
                }
            }
            .footer_link {
                .list {
                    display: flex !important;

                    &-item {
                        display: flex;
                        align-items: center;
                        
                        &:not(:last-child) {
                            &::after {
                                content: ".";
                                margin-left: 10px;
                                margin-top: -10px;
                                color: $white;
                                opacity: 0.5;
                            }
                        }
                        .link {
                            color: $white;
                            font-size: $font-size-base_xs;
                            font-weight: $font-weight-regular;
                            opacity: 0.5;

                            &:hover {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }
}
