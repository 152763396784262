// theme setup
@import './0_config/vendor-variables';
@import '~framework/assets/scss/Dev/0_config/vendor-variables';
@import '~framework/assets/scss/Dev/setup';
@import './0_config/website-variables';
@import '~framework/assets/scss/Dev/0_config/website-variables';

// styles
@import '~framework/assets/scss/Dev/1_vendor/bootstrap';

body.default {
	@import '~framework/assets/scss/Dev/base';

	@import './2_layout/header';
	@import './2_layout/footer';
	@import './2_layout/section';
	@import './2_layout/base';
	@import './2_layout/mini-sab';

	@import './3_component/eyecatcher';
	@import './3_component/card';
	@import './3_component/button';

	@import './4_page_block/collections';
}

@import './6_theme/tommy.matrix';
@import './6_theme/tommybooking';
