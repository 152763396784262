.content.intro {
    padding: 50px 0 280px;
    position: relative;

    &::after {
        background: url("/images/light-green-top-curve.svg") no-repeat top/100%;
        content: "";
        position: absolute;
        width: 100%;
        height: 170px;
        bottom: 0;
        right: 0;
    }
    .container-default {
        .container-holder {
            h1 {
                @extend .text-center;
            }
            p {
                font-weight: $font-weight-regular;
                font-size: $font-size-base / 1.0667;
                max-width: 730px;
                margin: auto;
            }
        }
    }
}
.bg-slant-top.bg-slant-bottom {
    background: $primary;
    position: relative;

    &::before {
        background: url("/images/green-curve.svg") no-repeat top/100%;
        content: "";
        position: absolute;
        width: 100%;
        height: 160px;
        top: -140px;
        z-index: 0;
        right: 0;
    }
    &::after {
        background: url("/images/light-green-top-curve.svg") no-repeat top/100%;
        content: "";
        position: absolute;
        width: 100%;
        height: 215px;
        bottom: 0;
        z-index: 0;
        right: 0;

        @include media-breakpoint-up(sm) {
            height: 400px;
        }
    }
    .container-default.slant-top {
        @include media-breakpoint-up(md) {
            transform: translateY(-40%);
        }

        .container-holder {
            .grid.page_block {
                .item {
                    @include media-breakpoint-up(sm) {
                        @include make-col(6);
                    }
                    .card {
                        border: 1px solid $base-1;

                        &-image {
                            &-link {
                                position: relative;

                                &::after {
                                    content: "";
                                    position: absolute;
                                    background: linear-gradient(180deg, transparent, $black);
                                    bottom: 0;
                                    left: 0;
                                    height: 90px;
                                    width: 100%;
                                    opacity: 0.5;
                                }
                                .card-img {
                                    height: 230px;
                                    object-fit: cover;

                                    @include media-breakpoint-up(sm) {
                                        height: 360px;
                                    }
                                }
                            }
                        }
                        .card-body {
                            position: relative;
                            padding: 15px 15px 45px !important;

                            .card-caption {
                                .card-title-link {
                                    position: absolute;
                                    top: -70px;
                                    color: $white;

                                    &:hover {
                                        .card-title {
                                            color: $tertiary;
                                        }
                                    }

                                    .card-title {
                                        font-size: $h2-font-size;
                                        color: $white !important;
                                        font-weight: $font-weight-regular !important;
                                    }
                                }
                                .card-subtitle {
                                    font-weight: $font-weight-regular !important;
                                }
                            }
                            .card-buttons {
                                .card-btn {
                                    margin-top: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .container-default.slant-bottom {
        position: relative;

        @include media-breakpoint-up(md) {
            transform: translateY(-150px);
            z-index: 1;
        }

        .container-holder {
            h2,
            p {
                color: $white;
            }
            h2 {
                @extend .text-center;
            }
            p {
                max-width: 713px;
                margin: auto auto 35px;
                @extend .text-center;
            }
            .grid {
                z-index: 11;
                position: relative;

                .item {
                    .card {
                        box-shadow: 3px 3px 6px rgba($black, 0.16);
                        border-radius: 5px;
                        border: none;

                        &:hover {
                            text-decoration: none;

                            .card-title {
                                color: $black !important;
                            }
                        }
                        &-body {
                            padding: 25px 15px 50px !important;

                            .card-caption {
                                .card-title-link {
                                    .card-title {
                                        color: $primary;
                                    }
                                }
                                .card-title {
                                    color: $primary;
                                    font-weight: $font-weight-regular;
                                }
                                .card-subtitle {
                                    font-weight: $font-weight-regular;
                                }
                                .card-description {
                                    min-height: 100%;
                                    max-height: 100%;
                                    margin-top: 0;
									pointer-events: none;

                                    &-content {
                                        font-weight: $font-weight-regular;
                                        font-size: $font-size-base / 1.0667;
                                    }
                                    &::after {
                                        content: none;
                                    }
                                }
                            }
                            .card-buttons {
                                .card-btn {
                                    @extend .btn-secondary;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.inspiration {
    position: relative;
    background: $primary;
    padding: 0 !important;
    z-index: 9;

    @include media-breakpoint-up(sm) {
        padding: 0;
    }

    &::before {
        background: url("/images/light-green-bottom-curve.svg") no-repeat bottom/100%;
        content: "";
        position: absolute;
        width: 100%;
        height: 90px;
        top: -50px;
        z-index: 2;
        right: 0;

        @include media-breakpoint-up(lg) {
            height: 160px;
        }
    }
    &::after {
        background: url("/images/green-bottom-curve.svg") no-repeat bottom/100%;
        content: "";
        position: absolute;
        width: 100%;
        height: 130px;
        bottom: 0;
        z-index: -1;
        right: 0;
        background-color: $white;
    }

    .container-two-columns {
        .container-holder {
            margin: 0 !important;

            &::before {
                background: url("/images/green-top-curve.svg") no-repeat top/100%;
                content: "";
                position: absolute;
                width: 100%;
                height: 160px;
                bottom: 260px;
                z-index: 1;
                right: 0;

                @include media-breakpoint-down(xs) {
                    content: none;
                }
            }
            .page_block.resource_image {
                margin-bottom: 30px;
                position: relative;

                @include media-breakpoint-up(lg) {
                    margin-bottom: 50px;
                }
                @include media-breakpoint-down(xs) {
                    &::after {
                        background: url("/images/green-top-curve.svg") no-repeat top/100%;
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 60px;
                        bottom: -30px;
                        z-index: 1;
                        right: 0;
                    }
                }

                img {
                    width: 100%;
                }
            }
            .column {
                padding: 0 0 20px !important;
                @include media-breakpoint-up(sm) {
                    padding-bottom: 100px !important;
                }
                h2,
                p {
                    color: $white;
                    max-width: 583px;
                    margin: auto;
                    z-index: 1;
                    position: relative;

                    @include media-breakpoint-down(lg) {
                        padding: 0 30px;
                    }
                }
                h2 {
                    font-weight: $font-weight-regular;
                }
                &.one {
                    position: relative;

                    &::after {
                        @include media-breakpoint-up(sm) {
                            content: "";
                            border-right: 2px solid $white;
                            width: 1px;
                            height: 100%;
                            position: absolute;
                            right: 0;
                            top: 0;
                            z-index: 1;
                        }
                    }
                }
                &.two {
                    .page_block.resource_image {
                        position: relative;
                        margin-bottom: 30px;

                        @include media-breakpoint-up(sm) {
                            margin-bottom: 0 !important;
                        }
                        @include media-breakpoint-down(xs) {
                            &::before {
                                background: url("/images/green-bottom-curve.svg") no-repeat bottom/100%;
                                content: "";
                                position: absolute;
                                width: 100%;
                                height: 35px;
                                top: 0;
                                z-index: 1;
                                right: 0;
                            }
                        }

                    }
                }
            }
        }
    }
}
.stay-info {
    padding: 100px 0 0;

    @include media-breakpoint-up(sm) {
        padding: 150px 0 0;
    }

    .container-three-columns {
        .container-holder {
            .column {
                h3 {
                    color: $primary;
                    font-size: $h3-font-size !important;
                    font-weight: $font-weight-regular;
                }
                p {
                    max-width: 320px;
                }
                .page_block.link {
                    @extend .btn-secondary;
                }
            }
        }
    }
}
@include media-breakpoint-down(sm) {
    &.default {
        &.home {
            .content {
                &.intro {
                    padding-bottom: 50px;
                    &::after {
                        display: none;
                    }
                }
            }
            .bg-slant-top {
                &.bg-slant-bottom {
                    background-color: transparent;
                    &::before {
                        display: none;
                    }
                    .slant-top {
                        position: relative;
                        &::before {
                            content: "";
                            background: url("/images/green-curve.svg") no-repeat top/100%;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            height: 170px;
                        }
                        &::after {
                            content: "";
                            background: url("/images/light-green-top-curve.svg") no-repeat top/100%;
                            position: absolute;
                            bottom: 60px;
                            left: 0;
                            width: 100%;
                            height: 150px;
                            z-index: -1;
                        }
                    }
                    .slant-bottom {
                        background: $primary;
                        padding-top: 50px;
                    }
                }
            }
        }
    }
}
